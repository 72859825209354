/**
 * Created by mac-mini3 on 26/08/15.
 */

var app = angular.module('product', ['ngAnimate', 'ui.bootstrap', 'ngMessages', 'jcs-autoValidate', 'ngFileUpload', 'ngImgCrop', 'ngMap', 'ui.mask', 'angularUtils.directives.dirPagination']).
    config(function ($interpolateProvider, $httpProvider) {
        $interpolateProvider.startSymbol('<[');
        $interpolateProvider.endSymbol(']>');
        $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
        $httpProvider.interceptors.push('myHttpInterceptor');
});

angular.module('ngMap').run(function ($rootScope) {
    $rootScope.address = '';
});
